// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import { BrowserRouter } from 'react-router-dom';
// import {Provider} from 'react-redux';
// import {store} from './store/store';
// import reportWebVitals from "./reportWebVitals";
// import SimpleReactLightbox from "simple-react-lightbox";
// import  ThemeContext  from "./context/ThemeContext"; 
// import { LoaderWrapper } from './context/LoaderWrapper';
// import { LoaderProvider } from './context/LoaderContext';

// // ReactDOM.render(
// //     <React.StrictMode>
// //         <LoaderProvider>
// //         <Provider store={store}>
// //         <LoaderHandler />
// //             <SimpleReactLightbox>
// //                     <BrowserRouter basename='/app/iwashwell'>
// //                         <ThemeContext>
// //                             <App />
// //                         </ThemeContext>
// //                     </BrowserRouter>
// //             </SimpleReactLightbox>
// //         </Provider>
// //         </LoaderProvider>
// //     </React.StrictMode>,
// //     document.getElementById("root")
// // );

// ReactDOM.render(  
//     <LoaderWrapper>  
//         <Provider store={store}>  
//         <BrowserRouter basename='/app/iwashwell'> 
//         <ThemeContext>
//                 <App />
//                 </ThemeContext>   
//             </BrowserRouter>  
//         </Provider>  
//     </LoaderWrapper>,  
//     document.getElementById('root')  
// );

// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { LoaderProvider } from './context/LoaderContext';
import { LoaderHandler } from './context/LoaderHandler';
import ThemeContext from './context/ThemeContext'; // Make sure this path is correct
import reportWebVitals from "./reportWebVitals";
import App from './App';
import {store} from './store/store';

ReactDOM.render(
    <React.StrictMode>
        <LoaderProvider>
            <Provider store={store}>
                <LoaderHandler />
                <SimpleReactLightbox>
                    <BrowserRouter basename='/app/iwashwell'>
                        <ThemeContext>
                            <App />
                        </ThemeContext>
                    </BrowserRouter>
                </SimpleReactLightbox>
            </Provider>
        </LoaderProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
