import axios from 'axios';

var URL = "http://138.197.145.194:7002/"

// Create axios instance with default config
const api = axios.create({
    baseURL: URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add request interceptor
api.interceptors.request.use(
    (config) => {
        // Add token to all requests
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `token ${token}`;
        }
        // Dispatch loader start event
        window.dispatchEvent(new CustomEvent('apiRequestStart'));
        return config;
    },
    (error) => {
        window.dispatchEvent(new CustomEvent('apiRequestEnd'));
        return Promise.reject(error);
    }
);

// Add response interceptor
api.interceptors.response.use(
    (response) => {
        window.dispatchEvent(new CustomEvent('apiRequestEnd'));
        return response;
    },
    (error) => {
        window.dispatchEvent(new CustomEvent('apiRequestEnd'));
        return Promise.reject(error);
    }
);

export const LoginToapp = (value) => {
    return URL + 'iwash_app/login/';
};

export const addMember = (value) => {
    const form = new FormData();
    for (const key in value) {
        if (key === 'dl_proof' || key === 'income_proof' || key === 'land_own_proof' || key === 'water_usage_proof') {
            if (value[key][0] !== undefined) {
                form.append(key, value[key][0]);
            } else {
                form.append(key, '');
            }
        } else {
            form.append(key, value[key]);
        }
    }
    
    return api.post('iwash_app/membercreate/', form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const updateMember = (value, id) => {
    const form = new FormData();
    for (const key in value) {
        if (key === 'dl_proof' || key === 'income_proof' || key === 'land_own_proof' || 
            key === 'water_usage_proof' || key === 'signed_form') {
            if (value[key][0] !== undefined) {
                form.append(key, value[key][0]);
            } else {
                form.append(key, '');
            }
        } else {
            form.append(key, value[key]);
        }
    }

    return api.put(`iwash_app/memberupdate/${id}/`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const getMemberList = value => {
    return api.get(`iwash_app/memberlist/?status=${value}`);
};

export const updateMemberWFStatus = (value, id) => {
    const form = new FormData();
    form.append('status', value);
    form.append('member_id', id);
    return api.put('iwash_app/mem_work_flow_status_change/', form);
};

export const deleteMemberTrack = (id) => {
    return api.delete(`iwash_app/memberdelete/${id}/`);
};

export const uploadMembers = (files) => {
    const formData = new FormData();
    files.forEach(file => {
        formData.append('json_files', file);
    });

    return api.post('iwash_app/upload_members/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const addPaymentTrack = (value) => {
    return api.post('iwash_app/pay_trackcreate/', value);
};

export const getPayHistoryList = () => {
    return api.get('iwash_app/pay_tracklist/');
};

export const getPayHistoryNewList = () => {
    return api.get('iwash_app/member_payment_list/');
};

export const getPayHistoryNewDetails = value => {
    return api.get(`iwash_app/member_payment_details/?member_id=${value}`);
};

export const updatePaymentTrack = (value) => {
    return api.put('iwash_app/member_update_payment/', value);
};

export const deletePaymentTrack = (id) => {
    return api.delete(`iwash_app/pay_trackdelete/${id}/`);
};

export const addContractor = (value) => {
    return api.post('iwash_app/contractorcreate/', value);
};

export const updateContractor = (value, id) => {
    return api.put(`iwash_app/contractorupdate/${id}/`, value);
};

export const getContractorList = () => {
    return api.get('iwash_app/contractorlist/');
};

export const deleteContractor = (id) => {
    return api.delete(`iwash_app/contractordelete/${id}/`);
};

// Workflow Application
export const getWFApplicationList = value => {
    return api.get(`iwash_app/applicationlist?status=${value}`);
};

export const updateWFApplication = (value, id) => {
    const form = new FormData();
    form.append('status', value);
    return api.put(`iwash_app/applicationupdate/${id}/`, form);
};

export const deleteWFApplication = (id) => {
    return api.delete(`iwash_app/applicationdelete/${id}/`);
};

// Workflow Soil
export const getWFSoilList = value => {
    return api.get(`iwash_app/soil_testlist?status=${value}`);
};

export const updateWFSoil = (value, id) => {
    const form = new FormData();
    for (const key in value) {
        if (key === 'document') {
            if (value[key][0] !== undefined) {
                form.append(key, value[key][0]);
            } else {
                form.append(key, '');
            }
        } else {
            form.append(key, value[key]);
        }
    }

    return api.put(`iwash_app/soil_testupdate/${id}/`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const deleteWFSoil = (id) => {
    return api.delete(`iwash_app/soil_testdelete/${id}/`);
};

// Workflow Engineering
export const getWFEngineeringList = value => {
    return api.get(`iwash_app/engg_designlist?status=${value}`);
};

export const updateWFEngineering = (value, id) => {
    const form = new FormData();
    for (const key in value) {
        if (key === 'revised_schematics') {
            if (value[key][0] !== undefined) {
                form.append(key, value[key][0]);
            } else {
                form.append(key, '');
            }
        } else {
            form.append(key, value[key]);
        }
    }

    return api.put(`iwash_app/engg_designupdate/${id}/`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const deleteWFEngineering = (id) => {
    return api.delete(`iwash_app/engg_designdelete/${id}/`);
};

// Workflow Installation
export const getWFInstallationList = value => {
    return api.get(`iwash_app/installationlist?status=${value}`);
};

export const updateWFInstallation = (value, id) => {
    const form = new FormData();
    for (const key in value) {
        if (key === 'cep_install_cert' || key === 'cep_engg_cert' || 
            key === 'convenant' || key === 'others') {
            if (value[key][0] !== undefined) {
                form.append(key, value[key][0]);
            } else {
                form.append(key, '');
            }
        } else {
            form.append(key, value[key]);
        }
    }

    return api.put(`iwash_app/installationupdate/${id}/`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const deleteWFInstallation = (id) => {
    return api.delete(`iwash_app/installationdelete/${id}/`);
};

// Workflow ElecAndPlum
export const getWFElecAndPlumList = value => {
    return api.get(`iwash_app/electrical_plumbinglist?status=${value}`);
};

export const updateWFElecAndPlum = (value, id) => {
    const form = new FormData();
    for (const key in value) {
        if (key === 'elect_document' || key === 'plumb_document' || 
            key === 'land_own_proof' || key === 'water_usage_proof') {
            if (value[key][0] !== undefined) {
                form.append(key, value[key][0]);
            } else {
                form.append(key, '');
            }
        } else {
            form.append(key, value[key]);
        }
    }

    return api.put(`iwash_app/electrical_plumbingupdate/${id}/`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const deleteWFElecAndPlum = (id) => {
    return api.delete(`iwash_app/electrical_plumbingdelete/${id}/`);
};

// Workflow Summary
export const getWFSummaryList = value => {
    return api.get(`iwash_app/summarylist?status=${value}`);
};

export const getWFSummaryDetails = value => {
    return api.get(`iwash_app/summarydetails?member_id=${value}`);
};

// Workflow MemberHeader
export const getMemberHeaderList = value => {
    return api.get(`iwash_app/headerlist?member_id=${value}`);
};

// Status Change
export const updateWFStatus = (value, id, stage) => {
    const form = new FormData();
    form.append('status', value);
    form.append('member_id', id);
    form.append('stage_name', stage);
    return api.put('iwash_app/work_flow_status_change/', form);
};

// View Invoice
export const ViewInvoice = (value, id, stage) => {
    return api.get(`iwash_app/invoice/?member_id=${id}&month=${value}&year=${stage}`);
};

// Download Document
export const DownloadDocument = (value) => {
    return api.get(`iwash_app/download/?file_id=${value}`, {
        responseType: 'blob'
    });
};
