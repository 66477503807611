// src/context/LoaderContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import loaderGif from '../images/Wedges-3s-241px.gif';

const LoaderContext = createContext();

export function LoaderProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const [pendingRequests, setPendingRequests] = useState(0);

    useEffect(() => {
        console.log('Current loading state:', loading);
        console.log('Current pending requests:', pendingRequests);
    }, [loading, pendingRequests]);

    const showLoader = () => {
        console.log('ShowLoader called');
        setLoading(true);
        setPendingRequests(prev => prev + 1);
    };

    const hideLoader = () => {
        console.log('HideLoader called');
        setPendingRequests(prev => {
            const newCount = Math.max(0, prev - 1);
            if (newCount === 0) {
                console.log('Setting loading to false');
                setLoading(false);
            }
            return newCount;
        });
    };

    return (
        <>
            <LoaderContext.Provider value={{ loading, showLoader, hideLoader }}>
                {children}
            </LoaderContext.Provider>
            {loading && (
                <img 
                    src={loaderGif} 
                    alt="Loading..." 
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '999999',
                        width: '241px', // Original size of your GIF
                        height: 'auto',
                        background: 'transparent'
                    }}
                />
            )}
        </>
    );
}

export const useLoader = () => useContext(LoaderContext);