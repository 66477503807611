// src/context/LoaderHandler.js
import { useEffect } from 'react';
import { useLoader } from './LoaderContext';

export function LoaderHandler() {
    const { showLoader, hideLoader } = useLoader();

    useEffect(() => {
        const handleRequestStart = () => {
            console.log('API Request Started');
            showLoader();
        };

        const handleRequestEnd = () => {
            console.log('API Request Ended');
            // Add a small delay before hiding
            setTimeout(() => {
                hideLoader();
            }, 100);
        };

        window.addEventListener('apiRequestStart', handleRequestStart);
        window.addEventListener('apiRequestEnd', handleRequestEnd);

        return () => {
            window.removeEventListener('apiRequestStart', handleRequestStart);
            window.removeEventListener('apiRequestEnd', handleRequestEnd);
        };
    }, [showLoader, hideLoader]);

    return null;
}